export const flagList = [
    
            {
                label: "Is Parent Question",
                value: "is_parent",
                answer_type: ["SELECT","RADIO"],
                id: 1,
                checkCondition: true,
                condition: (values) => values.is_child === 'no'
            },
            {
                label: "Is Child Question",
                value: "is_child",
                answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
                id: 1,
                checkCondition: true,
                condition: (values) => values.is_parent === 'no'
            },
            {
                label: "Re-Type Question",
                value: "re_type",
                answer_type: ["TEXT"],
                id: 1,
                checkCondition: false,
                condition: true
            },
            {
                label: "To Be Masked",
                value: "is_to_be_mask",
                answer_type: ["TEXT", "NUMBER"],
                id: 2,
                checkCondition: false,
                condition: true
            },
            {
                label: "Is Odm",
                value: "is_odm",
                answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
                id: 3,
                checkCondition: true,
                condition: (values) => values.is_odm === 'yes'
            },
            {
                label: "Related",
                value: "related",
                answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
                id: 4,
                checkCondition: false,
                condition: true
            },
            {
                label: "Required",
                value: "required",
                answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
                id: 5,
                checkCondition: false,
                condition: true
            },
            {
                label: "Is Searchable",
                value: "is_to_be_search",
                answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
                id: 6,
                checkCondition: false,
                condition: null
            }
        
]