import React from 'react';
import Modal from 'react-modal';
import { FadeLoader } from 'react-spinners';

const customStyles = {
    content: {
        position: 'absolute',
        width: '10%',
        top: '40%',
        bottom: '10%',
        padding: '35px',
        overflow: 'none',
        zIndex: '2',
        left: '40%',
        right: '10%',
        height: '20%',
        
    }
};

const LoaderComponent = (props) => {
    return (
        <Modal isOpen={props.isLoading} ariaHideApp={false} scrollable="true" style={customStyles} contentLabel="Loading">
            <div className="container">
                <div className="" style={{ height: '10vh' }}>
                    <FadeLoader height= {24} width={5} color="#0099CC" loading={props.isLoading} text={'loading'} />
                    
                </div>
                
            </div>
            <span className="font-weight-bold h5">Loading...</span>
        </Modal>
    );
};

export default LoaderComponent;