import React, { Fragment } from 'react';
import { deleteNotification, fetchConfiguredQuestionsForNotifications, AddNotification, UpdateNotification, getNotificationList, getNotificationDetails, publishnotifications } from '../../api/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { BeatLoader } from 'react-spinners';
import { BsTrash, BsPencilSquare } from 'react-icons/bs';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';

const Option = (props) => {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const colourStyles = {
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected && 'transparent',
            color: 'black'
        };
    },
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999 // Ensure the menu appears on top of other elements
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 38, // Set the fixed height for the value container
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }),
    singleValue: (base) => ({
        ...base,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    })
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class NotificationConfigureForm extends React.Component {
    constructor() {
        super();
        this.state = {
            displayLink: '',
            conditions: [{ section: '', question: '', answer_type: '', questionsList: [], answersList: [], selectedAnswers: [], comparison: '==', grouping: 'yes' }],
            selections: [{ section: '', question: '', questionsList: [] }],
            time_filter: '1',
            date_field: '',
            days_count: null,
            comparison: '',
            sections: [],
            showConfirmModal: false,
            action: '',
            showMessage: false,
            notificationsList: [],
            notificationId: null,
            showLoader: false,
            selectedNotification: null,
            customerId: null,
            selectedOptions: []
        };
    }

    setInitialState() {
        const firstSection = this.state.sections[0];
        const firstSectionQuestion = firstSection.questions[0];
        const defaultSelected = { section: firstSection?.section, question: firstSectionQuestion.question, questionsList: firstSection.questions };
        this.setState({
            displayLink: '',
            conditions: [{ section: '', question: '', answer_type: '', questionsList: [], answersList: [], selectedAnswers: [], comparison: '==', grouping: 'yes' }],
            selections: [defaultSelected],
            time_filter: '1',
            date_field: '',
            days_count: 0,
            comparison: '',
            selectedNotification: null,
            action: ''
        });
    }

    componentDidMount = async () => {
        let { id, Config } = this.props.match.params;
        this.setState({ customerId: id });
        if (id) {
            let response = await fetchConfiguredQuestionsForNotifications(id, Config);
            this.setState({ sections: response.response });
            const datequestions = this.state.sections?.map((section) => section.questions.filter((item) => item.answer_type === 'DATE'));
            const filteredDateQuestions = datequestions?.flat(1);
            this.setState({ datequestions: filteredDateQuestions });
            const firstSection = this.state.sections[0];
            const firstSectionQuestion = firstSection.questions[0];
            const defaultSelected = { section: firstSection?.section, question: firstSectionQuestion.question, questionsList: firstSection.questions };
            this.setState({ selections: [defaultSelected] });
            this.getNotificationsList();
        }
    };

    addRow(fieldname) {
        if (fieldname === 'conditions') {
            this.setState({
                conditions: [...this.state.conditions, { section: '', question: '', answer_type: '', questionsList: [], answersList: [], selectedAnswers: [], comparison: '', grouping: 'yes' }]
            });
        } else {
            this.setState({
                selections: [...this.state.selections, { section: '', question: '', questionsList: [] }]
            });
        }
    }
    removeRow(index, fieldname) {
        if (fieldname === 'conditions') {
            const rows = [...this.state.conditions];
            rows.splice(index, 1);
            this.setState({
                conditions: rows
            });
        } else {
            const rows = [...this.state.selections];
            rows.splice(index, 1);
            this.setState({
                selections: rows
            });
        }
    }

    handleClose = () => {
        this.setState({ showConfirmModal: false });
    };
    //based upon particular action calling respective methods
    handleNotifications = () => {
        const action = this.state.action;
        if (action === 'submit') {
            this.addNotification();
        } else if (action === 'delete') {
            this.confirmDeleteNotification();
        } else {
            this.confirmPublishNotification();
        }
    };
    handleSelectChange = (index, fieldname) => (evnt) => {
        if (fieldname === 'conditions') {
            const { name, value } = evnt.target;
            const list = [...this.state.conditions];
            list[index][name] = value;
            this.setState({ conditions: list });
            if (name === 'section') {
                if (value.length > 0) {
                    const selectedSectionDetails = this.state.sections.filter((item) => item.section === value);
                    const selectedSectionRelatedQuestions = selectedSectionDetails.length > 0 && selectedSectionDetails[0].questions;
                    //Remove questions of answerType date
                    const filteredQuestions = selectedSectionRelatedQuestions.filter((ques) => ques.answer_type !== 'DATE');
                    list[index]['questionsList'] = filteredQuestions;
                    this.setState({ conditions: list });
                } else {
                    list[index] = { section: '', question: '', answer: '', answer_type: '', questionsList: [], answersList: [] };
                    this.setState({ conditions: list });
                }
            } else if (name === 'question') {
                if (value.length > 0) {
                    const selectedQuestionDetails = list[index].questionsList.filter((item) => item.question === value);
                    const selectedQuestionAnswers = selectedQuestionDetails.length > 0 && selectedQuestionDetails[0].suggested_answers;
                    const selectedQuestionAnswerType = selectedQuestionDetails.length > 0 && selectedQuestionDetails[0].answer_type;
                    list[index]['answer_type'] = selectedQuestionAnswerType;
                    list[index]['answersList'] = selectedQuestionAnswers;
                    this.setState({ conditions: list });
                } else {
                    list[index]['answer_type'] = '';
                    list[index]['answer'] = '';
                    list[index]['answersList'] = [];
                    this.setState({ conditions: list });
                }
            }
        } else {
            const { name, value } = evnt.target;
            const list = [...this.state.selections];
            list[index][name] = value;
            this.setState({ selections: list });
            if (name === 'section') {
                if (value.length > 0) {
                    const selectedSectionDetails = this.state.sections.filter((item) => item.section === value);
                    const selectedSectionRelatedQuestions = selectedSectionDetails.length > 0 && selectedSectionDetails[0].questions;
                    list[index]['questionsList'] = selectedSectionRelatedQuestions;
                    this.setState({ selections: list });
                } else {
                    list[index] = { section: '', question: '', answer: '', answer_type: '', questionsList: [], answersList: [] };
                    this.setState({ conditions: list });
                }
            }
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    };

    onCheckBoxChange = (index) => (e) => {
        const { value } = e.target;
        const list = [...this.state.conditions];
        if (value === 'yes') {
            list[index]['grouping'] = 'no';
            this.setState({ conditions: list });
        } else {
            list[index]['grouping'] = 'yes';
            this.setState({ conditions: list });
        }
    };

    onRadioChange = (e) => {
        this.setState({
            time_filter: e.currentTarget.value
        });
    };

    editNotificationDetails = async () => {
        const { id } = this.props.match.params;
        const conditions = this.state.conditions?.map((item) => {
            let answervalues = [];
            const filteredArray = item.answersList.filter((answer) => item.selectedAnswers.some((filterItem) => filterItem.value === answer.id));
            const selectedIds = filteredArray.map((ob) => (!isNaN(parseInt(ob.id)) ? parseInt(ob.id) : ob.id));
            const selectedValues = filteredArray.map((obj) => obj.value);
            answervalues = item.answer_type === 'NUMBER' || item.answer_type === 'TEXT' ? selectedValues : selectedIds;
            return {
                question: item.question,
                values: answervalues,
                comparison: item.comparison,
                grouping: item.grouping
            };
        });
        const filteredSelections = this.state.selections?.map((item) => item.question);
        const data = {
            customer: id,
            name: this.state.displayLink,
            conditions: conditions,
            selections: filteredSelections,
            time_filter: parseInt(this.state.time_filter),
            date_field: this.state.date_field,
            days_count: this.state.days_count ? parseInt(this.state.days_count) : 0,
            comparison: this.state.comparison
        };
        try {
            const response = await UpdateNotification(data, this.state.selectedNotification.id);
            if (response.status === 'failed') {
                if (response.message === "You can't update published notification") {
                    toast.warning(response?.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000
                    });
                } else {
                    toast.error('Unable to update notification', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000
                    });
                }
            } else {
                toast.info(`Notification updated successfully.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
                this.getNotificationsList();
            }
        } catch (e) {
            toast.error(`Unable to update notification`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    handleFormEdit = () => {
        const reqconditions = this.state.conditions?.some((obj) => obj.hasOwnProperty('question') && obj.question && obj.hasOwnProperty('comparison') && obj.comparison);
        const reqselections = this.state.selections?.some((obj) => obj.hasOwnProperty('question') && obj.question);
        const reqdayscount=this.state.days_count !== '';
        
        const conditions =  parseInt(this.state.time_filter) === 2 ? reqconditions && reqselections && reqdayscount : reqconditions && reqselections;
        if (conditions) {
            this.editNotificationDetails();
        } else {
            toast.info(`Please fill out the required fields`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    handleFormSubmit = () => {
        this.setState({ action: 'submit' });
        const reqconditions = this.state.conditions?.some((obj) => obj.hasOwnProperty('question') && obj.question && obj.hasOwnProperty('comparison') && obj.comparison);
        const reqselections = this.state.selections?.some((obj) => obj.hasOwnProperty('question') && obj.question);
        const reqdayscount=this.state.days_count !== '';
        const conditions =  parseInt(this.state.time_filter) === 2 ? reqconditions && reqselections && reqdayscount : reqconditions && reqselections;
        if (conditions) {
            this.setState({ showConfirmModal: true });
        } else {
            toast.info(`Please fill out the required fields`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    addNotification = async () => {
        const { id } = this.props.match.params;
        const conditions = this.state.conditions?.map((item) => {
            let answervalues = [];
            const filteredArray = item.answersList.filter((answer) => item.selectedAnswers.some((filterItem) => filterItem.value === answer.id));
            const selectedIds = filteredArray.map((ob) => (!isNaN(parseInt(ob.id)) ? parseInt(ob.id) : ob.id));
            const selectedValues = filteredArray.map((obj) => obj.value);
            answervalues = item.answer_type === 'NUMBER' || item.answer_type === 'TEXT' ? selectedValues : selectedIds;
            return {
                question: item.question,
                values: answervalues,
                comparison: item.comparison,
                grouping: item.grouping
            };
        });

        const filteredSelections = this.state.selections?.map((item) => item.question);
        const data = {
            customer: id,
            name: this.state.displayLink,
            conditions: conditions,
            selections: filteredSelections,
            time_filter: parseInt(this.state.time_filter),
            date_field: this.state.date_field,
            days_count: this.state.days_count ? parseInt(this.state.days_count) : 0,
            comparison: this.state.comparison
        };
        try {
            const response = await AddNotification(data);
            if (response.status === 'failed') {
                toast.warning(response?.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });

                this.handleClose();
            } else {
                toast.info(`Notification configured successfully.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
                this.handleClose();
                this.setInitialState();
                this.getNotificationsList();
            }
        } catch (e) {
            toast.error(`Unable to configure notification`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
            this.handleClose();
        }
    };

    async getNotificationsList() {
        this.setState({
            showLoader: true
        });
        try {
            let notifications = await getNotificationList(this.state.customerId);
            this.setState({ notificationsList: notifications, showLoader: false });
        } catch (e) {
            this.setState({ showLoader: false });
        }
    }

    async getEditNotificatioDetails(item) {
        this.setState({ action: 'edit', selectedNotification: item });
        try {
            let response = await getNotificationDetails(item.id);
            let selectedConditions = [];
            const selectedCondition = response?.conditions.map((item) => {
                const section = this.state.sections.filter((obj) => obj.questions.some((ques) => ques.question === item.question));

                const selectedQues = section[0]?.questions.filter((ques) => ques.question === item.question);
                const answer_type = selectedQues[0]?.answer_type;
                const answerlist = selectedQues[0]?.suggested_answers;
                const questionslist = section[0]?.questions;
                const filteredQuestionslist = questionslist.filter((ques) => ques.answer_type !== 'DATE');
                const selectedAnswers = answer_type === 'NUMBER' || answer_type === 'TEXT' ? answerlist.filter((answer) => item.values.some((filterItem) => filterItem === answer.value)) : answerlist.filter((answer) => item.values.some((filterItem) => filterItem === answer.id));
                const formattedSelectedAnswers = selectedAnswers.map((answer) => {
                    return { value: answer.id, label: answer.value === '' ? 'Not Answered' : answer.value };
                });
                selectedConditions.push({
                    question: item.question,
                    section: section[0]?.section,
                    questionsList: filteredQuestionslist,
                    answersList: answerlist,
                    answer_type: answer_type,
                    comparison: item.comparison,
                    grouping: item.grouping,
                    selectedAnswers: formattedSelectedAnswers,
                    values: item.values
                });
                return selectedConditions;
            });

            const selectedSelection = response?.selections.map((item) => {
                let selectedSelection = [];
                const section = this.state.sections.filter((section) => section.questions.some((element) => element.question === item));
                const questionslist = section[0]?.questions;
                selectedSelection.push({ section: section[0]?.section, question: item, questionsList: questionslist });
                return selectedSelection;
            });

            const selections = selectedSelection.flat(1);
            this.setState({
                displayLink: response?.name,
                conditions: selectedConditions,
                selections: selections,
                time_filter: response?.time_filter.toString(),
                date_field: response?.date_field,
                days_count: response?.days_count,
                comparison: response?.comparison
            });
        } catch (e) {
            toast.info(`Unable to get notification details`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
            this.setInitialState();
        }
    }

    handleDeleteNotification(item) {
        this.setState({ showConfirmModal: true, selectedNotification: item, action: 'delete' });
    }

    handlePublishNotification(item) {
        this.setState({ showConfirmModal: true, selectedNotification: item, action: 'publish' });
    }

    async confirmDeleteNotification() {
        const id = this.props.match.params;
        const item = this.state.selectedNotification;
        if (item.is_published === 'yes') {
            toast.warning('Published notification cannot be deleted', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            this.handleClose();
        } else {
            try {
                let response = await deleteNotification(item.id);
                if (response.status === 'failed') {
                    toast.error('Delete notification failed', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                    this.handleClose();
                } else {
                    toast.info('Notification deleted successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                    this.handleClose();
                    this.getNotificationsList();
                    this.setInitialState();
                }
            } catch (error) {
                toast.error('Delete notification failed', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                this.handleClose();
            }
        }
    }
    async confirmPublishNotification() {
        const item = this.state.selectedNotification;
        const publishValue = item.is_published === 'no' ? 'yes' : 'no';
        const successMessage = item.is_published === 'no' ? 'Notification published successfully' : 'Notification unpublished successfully';
        const errorMessage = item.is_published === 'no' ? 'Unable to publish notification' : 'Unable to unpublish notification';
        try {
            let response = await publishnotifications(item.id, publishValue);
            if (response.status === 'failed') {
                toast.info(errorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                this.handleClose();
            } else {
                toast.info(successMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                this.handleClose();
                this.getNotificationsList();
                this.setInitialState();
            }
        } catch (error) {
            toast.info(errorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            this.handleClose();
        }
    }

    handleOptionChange = (optionId) => {
        this.setState((prevState) => {
            if (prevState.includes(optionId)) {
                // If the option is already selected, remove it from the selectedOptions array
                return prevState.filter((id) => id !== optionId);
            } else {
                // If the option is not selected, add it to the selectedOptions array
                return [...prevState, optionId];
            }
        });
    };

    handleMultiSelectChange = (selectedOption, index) => {
         const name = 'selectedAnswers';
        const list = [...this.state.conditions];
        list[index][name] = selectedOption;
        this.setState({ conditions: list });
    };
     filterSelectedQuestions = (index) => {
        let result = this.state.conditions.find((item, ind)=> index !== ind)?.question;
        
        return result;
     }
    render() {
        toast.configure();
        
        return (
            <div className="container-fluid mb-5" style={{ marginTop: '3rem' }}>
                <div className="col-12">
                    <form>
                        <div className="form-group field-row">
                            <label className="col-sm-2 col-form-label font-weight-bold ">Name/Display Link:</label>
                            <div className="col-sm-10">
                                <input type="text" onChange={this.handleChange} value={this.state.displayLink} name="displayLink" required className="form-control" />
                            </div>
                        </div>
                        {this.state.conditions.map((item, index) => (
                            <div key={index}>
                                <div className="form-group field-row" style={{ marginBottom: '2px' }}>
                                    <label className="col-sm-2 col-form-label font-weight-bold ">Condition {index + 1}:</label>
                                    <div className="col-sm-2">
                                        <select name="section" className="form-control" value={item.section} onChange={this.handleSelectChange(index, 'conditions')} required>
                                            <option value="">Select Section</option>
                                            {this.state.sections.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.section}>
                                                        {item.section}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-sm-2">
                                        <select name="question" className="form-control" value={item.question} onChange={this.handleSelectChange(index, 'conditions')} required>
                                            <option value="">Select Question</option>
                                            {item.questionsList.map((element, i) => (!this.filterSelectedQuestions(index)?.includes(element.question) ) && 
                                                 (
                                                    <option key={i} value={element.question}>
                                                        {element.question}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-2">
                                        <select value={item.comparison} name="comparison" onChange={this.handleSelectChange(index, 'conditions')} className="form-control" required>
                                            <option value="">Select Comparison</option>
                                            <option value="==">==</option>
                                            <option value="!=">!=</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-3">
                                        <span className="d-inline-block" data-toggle="popover" data-trigger="focus" data-content="answer" style={{ width: '100%' }}>
                                            <ReactSelect
                                                options={item.answersList.map((option) => {
                                                    if(option.value === '') {
                                                       return { value: option.id, label: 'Not Answered'};
                                                    } else {
                                                        return { value: option.id, label: option.value };
                                                    }
                                                    
                                                })}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                    Option
                                                }}
                                                onChange={(selectedOption) => this.handleMultiSelectChange(selectedOption, index)}
                                                allowSelectAll={true}
                                                menuPortalTarget={document.body}
                                                value={item.selectedAnswers}
                                                styles={colourStyles}
                                                placeholder="Select Answer"
                                            />
                                        </span>

                                        {index !== 0 && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '1px',
                                                    right: '-15px',
                                                    width: '25px',
                                                    height: '25px',
                                                    fontSize: '24px',
                                                    color: 'red'
                                                }}
                                                className="font-text-bold text-center "
                                                onClick={() => this.removeRow(index, 'conditions')}
                                            >
                                                <i className="fa fa-remove"></i>
                                            </div>
                                        )}

                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '1px',
                                                right: '-40px',
                                                width: '25px',
                                                height: '25px',
                                                fontSize: '24px'
                                            }}
                                            className="font-text-bold text-center "
                                            onClick={() => this.addRow('conditions')}
                                        >
                                            <i
                                                className="fa fa-plus"
                                                style={{
                                                    fontSize: '28px'
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-11" style={{ textAlign: 'right' }}>
                                    <input className="form-check-input" style={{ marginLeft: '0.75rem' }} checked={item.grouping === 'yes' ? true : false} type="checkbox" value={item.grouping} onChange={this.onCheckBoxChange(index)} />
                                    <label className="form-check-label" style={{ marginLeft: '2rem' }}>
                                        Required
                                    </label>
                                </div>
                            </div>
                        ))}

                        {this.state.selections.map((item, index) => (
                            <div key={index} className="form-group field-row">
                                <label className="col-sm-2 col-form-label font-weight-bold ">Selection {index + 1}:</label>
                                <div className="col-sm-3">
                                    <select name="section" value={item.section} className="form-control" disabled={index === 0 ? true : false} onChange={this.handleSelectChange(index, 'selections')} required>
                                        <option value="">Select Section</option>
                                        {this.state.sections.map((item, index) => {
                                            return (
                                                <option key={index} value={item.section}>
                                                    {item.section}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="col-sm-3">
                                    <select name="question" value={item.question} className="form-control" disabled={index === 0 ? true : false} onChange={this.handleSelectChange(index, 'selections')} required>
                                        <option value="">Select Question</option>
                                        {item.questionsList?.map((element, index) => {
                                            return (
                                                <option key={index} value={element.question}>
                                                    {element.question}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    {index !== 0 && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '1px',
                                                right: '-15px',
                                                width: '25px',
                                                height: '25px',
                                                fontSize: '24px',
                                                color: 'red'
                                            }}
                                            className="font-text-bold text-center "
                                            onClick={() => this.removeRow(index, 'selections')}
                                        >
                                            <i className="fa fa-remove"></i>
                                        </div>
                                    )}

                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '1px',
                                            right: '-40px',
                                            width: '25px',
                                            height: '25px',
                                            fontSize: '24px'
                                        }}
                                        className="font-text-bold text-center "
                                        onClick={() => this.addRow('selections')}
                                    >
                                        <i
                                            className="fa fa-plus"
                                            style={{
                                                fontSize: '28px'
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <fieldset className="form-group">
                            <div className="field-row">
                                <legend className="col-form-label col-sm-2 font-weight-bold">Time Filter:</legend>
                                <div className="col-sm-10">
                                    <div className="form-check form-check-inline">
                                        <input type="radio" name="time_filter" onChange={this.onRadioChange} checked={this.state.time_filter === '1'} className="form-check-input" value="1" required />
                                        <label className="form-check-label">Dates</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" name="time_filter" onChange={this.onRadioChange} checked={this.state.time_filter === '2'} className="form-check-input" value="2" />
                                        <label className="form-check-label">Days</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div className="form-group field-row">
                            <label className="col-sm-2 col-form-label font-weight-bold ">Pick Date Field:</label>
                            <div className="col-sm-3">
                                <select onChange={this.handleChange} value={this.state.date_field} name="date_field" className="form-control">
                                    <option value="">Select Date Question</option>
                                    {this.state.datequestions?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.question}>
                                                {item.question}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {this.state.time_filter === '2' && (
                                <div className="col-sm-3">
                                    <select value={this.state.comparison} placeholder="Select Comparator" name="comparison" onChange={this.handleChange} className="form-control">
                                        <option value="">Select Comparator</option>
                                        <option value="==">==</option>
                                        <option value="<=">&lt;=</option>
                                        <option value=">=">&gt;=</option>
                                        <option value="<">&lt;</option>
                                        <option value=">">&gt;</option>
                                    </select>
                                </div>
                            )}
                            {this.state.time_filter === '2' && (
                                <div className="col-sm-3">
                                    <input value={this.state.days_count} placeholder="Days Count" required onChange={this.handleChange} name="days_count" type="text" className="form-control" />
                                </div>
                            )}
                            
                        </div>

                        <div className="form-group field-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-sm-2">
                                <button type="button" onClick={() => this.setInitialState()} className="btn btn-primary font-weight-bold btn-block">
                                    Clear
                                </button>
                            </div>
                            <div className="col-sm-2">
                                <button type="button" onClick={this.state.action === 'edit' ? this.handleFormEdit : this.handleFormSubmit} className="btn btn-primary font-weight-bold btn-block">
                                    {this.state.action === 'edit' ? 'Update' : 'Submit'}
                                </button>
                            </div>
                        </div>

                        {this.state.showLoader ? (
                            <div>
                                <div className="text-center">
                                    <BeatLoader size={12} margin={2} color="#0099CC" />
                                </div>
                            </div>
                        ) : (
                            <div className="table-responsive ">
                                <table className="table table-striped mt-5 ">
                                    <thead className="bg-info text-white">
                                        <tr style={{ background: '#007bff' }}>
                                            <th bg-color="#007bff" scope="col mb-6">
                                                Name
                                            </th>
                                            <th bg-color="#007bff" scope="col mb-4">
                                                Status
                                            </th>
                                            <th bg-color="#007bff" scope="col mb-4">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.notificationsList.length > 0 ? (
                                            this.state.notificationsList?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.is_published === 'no' ? 'Not Published' : 'Published'}</td>
                                                        <td>
                                                            <BsPencilSquare fontSize={25} style={{ marginRight: '10px' }} onClick={() => this.getEditNotificatioDetails(item)} />
                                                            <BsTrash fontSize={25} onClick={() => this.handleDeleteNotification(item)} style={{ marginRight: '10px' }} />
                                                            <button type="button" onClick={() => this.handlePublishNotification(item)} className="btn btn-primary font-weight">
                                                                {item.is_published === 'no' ? 'Publish' : 'Unpublish'}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={3} className="text-center">
                                                    Notifications not yet configured
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </form>
                </div>
                <Modal isOpen={this.state.showConfirmModal} onRequestClose={this.handleClose} style={customStyles} ariaHideApp={false} contentLabel="Forgot Password">
                    <h4 className="text-primary">Are you sure to {this.state.action === 'submit' ? 'submit' : this.state.action === 'delete' ? 'delete' : this.state.selectedNotification?.is_published === 'no' ? 'publish' : 'unpublish'} this notification?</h4>
                    <div className="form-group row d-flex justify-content-center"></div>
                    <div className="row ">
                        <div className="col-6 text-center ">
                            <button type="button" className="button-pop" onClick={this.handleNotifications}>
                                Yes
                            </button>
                        </div>
                        <div className="col-6 text-center ">
                            <button type="button" className="button-pop" onClick={this.handleClose}>
                                No
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default NotificationConfigureForm;
