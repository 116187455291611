import React from 'react';
import Select from 'react-select';

const MultipleFlagSelect = ({flagOptions, 
                             sections, 
                            sectionId, 
                            questionId, 
                            data,
                            handleChange
                        }) => {
                            const values = {
                                is_odm: sections[sectionId].is_odm,
                                is_parent: sections[sectionId].questions[questionId].is_parent,
                                is_child: sections[sectionId].questions[questionId].is_child
                            };
        const QuestionList = () => {
           let filteredFlags =  flagOptions.filter((item) => (item.checkCondition ? item.condition(values) : item));
           
            let options = [{
                value: '',
                label: (
                    <div data-toggle="tooltip" data-placement="top" title='Select' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                        Select
                    </div>
                ),
                id: ''
            }];
            filteredFlags.map((item, i) => {
                    let exactQuestion = item.label;
                    if(item.answer_type.includes(data.answer_type)) {
                        options.push({
                            value: item.value,
                            label: (
                                <div data-toggle="tooltip" data-placement="top" title={exactQuestion} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                                    {exactQuestion}
                                </div>
                            ),
                            id: i
                        });
                    }
                    
                });
            return options;
        };

        const SelectedValue = 
        flagOptions.filter((item, i) => (sections[sectionId].questions[questionId][item.value] === 'yes') &&  (
                    {
                        value: item.value,
                        id: Number(i),
                        label: item.label
                    }
                ));
        

return (
    <Select 
                key={sectionId} 
                value={SelectedValue}
                name="multi_flag_select"
                data-id={questionId} 
                data-secid={sectionId} 
                id="multi_flag_select"
                options={QuestionList()} 
                className="basic-multi-select" 
                isMulti
                onChange={handleChange(sectionId, questionId)} 
                
    />
    )

}

export default MultipleFlagSelect;