import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Modal from 'react-modal';
import LoaderComponent  from '../LoaderComponent';

import './QuestionsConfigure.css';

const customStyles = {
    content: {
        position: 'absolute',
        width: '50%',
        top: '25%',
        bottom: '10%',
        left: '20%',
        right: '10%',
        height: '30%',
        overflowY: 'none',
        border: '2px solid #ccc',
        background: '#f0f0f0'
    }
};

const SubHeader = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    

    const onDrop = (acceptedFiles) => {
        setErrorMessage('')
        setSelectedFile(acceptedFiles[0]);
        setModalOpen(true);
    };

    const downloadHandler = () => {
     props.DownloadToolConfiguration(props.id, props.config);

    }

    const uploadHandler = async() => {
        const formData = new FormData();
            formData.append('customer', props.id);
            formData.append('config_type', props.config);
            formData.append('config_file', selectedFile);

            props.loadingHandler(true);
       await props.UploadToolConfiguration(formData, function(result) {
        props.loadingHandler(false);
        if(result) {
            if(result.status === 'success') {
                setSelectedFile(null);
                setModalOpen(false);
                window.location.reload();
            } else {
                setErrorMessage(result.message)
            }
            
        } 
       });


    }

     const handleClose = () => {
        setModalOpen(false);
        setErrorMessage('');
     }

    return (
        <Fragment>
            <div className="header">
                <div className="left">
                    <Link
                        to={{
                            pathname: `/admin/org-configure/`,
                            state: {
                                id: props.id
                            }
                        }}
                    >
                        <button className="back-button">Back</button>
                    </Link>
                </div>
                <div className="right"  >
                    <button onClick={() => downloadHandler()} className="sub-button" data-toggle="tooltip" data-placement="top" title="Download Configuration">
                        Download
                    </button>
                    <Dropzone onDrop={onDrop} accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                <button className="sub-button" data-toggle="tooltip" data-placement="top" title="Upload Configuration">
                                    Upload
                                </button>
                            </div>
                        )}
                    </Dropzone>
                </div>
            </div>
            <Modal isOpen={isModalOpen} ariaHideApp={false} onRequestClose={() => handleClose()} style={customStyles} scrollable="true" contentLabel="Example Modal">
                <h4 className="d-flex justify-content-center align-items-center">Are you sure to upload the Configuration?</h4>
                {errorMessage && <p className='text-danger'>{errorMessage}</p>}
                <p className='text-info'> Note: It is recommended to download current configuration before uploading new configuration for future reference.</p>
                <br />
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-primary" onClick={() => uploadHandler()} style={{width: '30%'}}>
                        Continue
                    </button>
                    <button className="btn btn-primary" onClick={() => setModalOpen(false)} style={{width: '30%'}}>
                       Cancel
                    </button>
                </div>
                <LoaderComponent isLoading={props.isLoading}/>
            </Modal>
        </Fragment>
    );
};

export default SubHeader;