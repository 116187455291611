import { fetchConfigureQuestions } from '../../api/api';

export const MapFamilyQuestion = async (org_type, id, config_type, fmlBasicRequirmentList) => {
    if (config_type !== 2 && org_type === 3) {
        let response = await fetchConfigureQuestions(id, 2);
        if(Array.isArray(response.response)) {
            
            for(let i = 0; i <= response.response.length-1; i++ ) {
                fmlBasicRequirmentList.push(response.response[i])
            }
        }
    }
    return fmlBasicRequirmentList;
};
