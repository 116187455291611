import React, { useState, Fragment } from 'react';
import { FaDownload, FaUpload } from 'react-icons/fa';
import Dropzone from 'react-dropzone';

import { UploadorDownloadAnswer } from '../../api/api';
import LoaderComponent  from '../LoaderComponent';

export const UploadOrDownloadBulkList = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
   const [isLoading, setLoading] = useState(false);
    const DownloadHandler = async (type, id, idx) => {
        const formData = new FormData();
        let list = props.MapAnswerList.length > 0 ? JSON.stringify(props.MapAnswerList[0]?.list) : '';
        formData.append('answers', list);
        await UploadorDownloadAnswer(formData);
    };


    const onDrop = (acceptedFiles) => {
        setSelectedFile(acceptedFiles[0]);
    };

    const UploadHandler = async (id, idx) => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('answers_file', selectedFile);
            try {
                
                setLoading(true);
                let result = await UploadorDownloadAnswer(formData);
                
                   await props.appendUploadedAnswers(result, props.id, props.idx, props.idy, props.MapAnswerList);
                    setLoading(false);
            } catch (error) {
                console.error(error, 'error');
            }
        }
    };

    return (
        <React.Fragment>
        <fieldset className="form-group">
           <div className="form-check form-check-inline">
                        <FaDownload onClick={() => DownloadHandler()} style={{ fontSize: '30px', color: '#007bff', cursor: 'pointer', marginRight: '5px' }} />
                        <legend className="col-form-label font-weight-bold">Download Template</legend>
                    </div>
                    <div className="form-check form-check-inline" style={{ position: 'absolute', cursor: 'pointer' }}>
                        <Dropzone onDrop={onDrop} accept="image/*">
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className="dropzone">
                                    <input {...getInputProps()} />
                                    <FaUpload style={{ fontSize: '24px', color: 'green', marginRight: '5px' }} />
                                    {selectedFile ? (
                                        <Fragment>
                                            <strong>{selectedFile.name}</strong>
                                        </Fragment>
                                    ) : (
                                        <strong>Choose file</strong>
                                    )}
                                </div>
                            )}
                        </Dropzone>
                        <Fragment>
                                &nbsp;
                                <a className="btn btn-primary" style={{ color: '#fff' }} onClick={() => UploadHandler()}>
                                    Upload
                                </a>
                            </Fragment>
                     </div>
         </fieldset>
         <LoaderComponent isLoading={isLoading}/>
         </React.Fragment>
    );

};

